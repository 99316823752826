<template>
  <giftcard-welcome v-if="flowStep === GIFTCARD_FLOW_STEPS.welcome" @clicked="nextPage"/>
  <giftcard-credit-card v-else-if="flowStep === GIFTCARD_FLOW_STEPS.creditCard" @done="afterPayment" />
  <giftcard-success v-else :email="recipientEmail" />
</template>

<script>
import { mapGetters } from 'vuex';
import router from '@/router';
import Segment from '@/Segment';
import GiftcardCreditCard from './pages/GiftcardCreditCard';
import GiftcardWelcome from './pages/GiftcardWelcome';
import GiftcardSuccess from './pages/GiftcardSuccess';

export default {
  name: 'RiseupGiftcardFlow',
  components: {
    GiftcardWelcome,
    GiftcardCreditCard,
    GiftcardSuccess,
  },
  data() {
    return {
      flowStep: null,
      recipientEmail: undefined,
    };
  },
  async created() {
    Segment.trackUserGot('SaleFlow_Entered');
    this.flowStep = this.GIFTCARD_FLOW_STEPS.welcome;
  },
  computed: {
    ...mapGetters('riseupGiftcard', ['GIFTCARD_FLOW_STEPS']),
  },
  methods: {
    nextPage() {
      this.flowStep = this.GIFTCARD_FLOW_STEPS.creditCard;
    },
    afterPayment({ success, email }) {
      if (success) {
        this.recipientEmail = email;
        this.flowStep = this.GIFTCARD_FLOW_STEPS.done;
      }
    },
    onBack() {
      Segment.trackUserGot('SaleFlow_BackClicked', { currFlowStep: this.flowStep });
      if (this.flowStep === this.GIFTCARD_FLOW_STEPS.welcome) {
        router.push({ path: '/sr', query: this.$route.query });
      } else if (this.flowStep === this.GIFTCARD_FLOW_STEPS.creditCard) {
        this.flowStep = this.GIFTCARD_FLOW_STEPS.welcome;
      } else {
        this.flowStep = this.GIFTCARD_FLOW_STEPS.creditCard;
      }
    },
  },
};
</script>
