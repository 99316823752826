<template>
  <div class="purchase">
    <img class="header" :src="require('@/assets/subscription/checkout_header.svg')">

    <div class="top-section">
      <div class="main-title">שמחים שבחרת ברייזאפ גיפטקארד כמתנה!</div>
      <callout icon-path="subscription/discount.svg" title="איך זה יעבוד?" class="callout">
        <template v-slot:text>
          <span>קנו שישה חודשים ברייזאפ, קבלו לינק</span>
          <span>למייל, העבירו למי שתרצו את המתנה!</span>
        </template>
      </callout>
    </div>

    <div class="personal-info">
      <div class="title">פרטי בעל הכרטיס</div>
      <personal-info-form/>
      <email-input labelText="כתובת המייל שלך"
                   :value="emailAddress"
                   :onInput="setEmailAddress"
                   :onError="setIsEmailInvalid"/>
      <div class="email-explained">למייל הזה ישלח השובר שאותו אפשר להעביר הלאה כמתנה</div>

    </div>
    <div class="separator"/>
    <div class="credit-card-details">
      <credit-card-details-form @init-failed="creditCardFormInitFailed"/>
    </div>
    <div class="separator"></div>
    <div class="summary">
      <div class="payment-details">
        <div class="amount-wrapper">
          <div class="amount-title">סה״כ לתשלום</div>
          <div class="title">180 ₪</div>
        </div>
      </div>
      <div class="button-container" v-if="!tokenizationError">
        <riseup-button title="לתשלום מאובטח" :size="'small'" :action="onPaymentClicked" :loading="loadingToken"
                       :disabled="loadingToken || !validPersonalInfo || !validEmail || buttonClicked"/>
      </div>
      <div class="result-container" v-else>
        <div class="top">
          <div class="icon">
            <img class="icon" :src="require('@/assets/icons/alert-icon.svg')">
          </div>
          <div class="message">
            לא הצלחנו לחייב את הכרטיס
          </div>
        </div>
        <div class="cta">
          אפשר לרענן את העמוד ולנסות להשתמש בכרטיס אחר.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapState, mapGetters, mapActions } from 'vuex';
import PersonalInfoForm from '@/pages/responsive-pages/authenticated/navigation-pages/account-settings/components/PersonalInfoForm';
import PaymentHelper from '@/pages/responsive-pages/authenticated/payment/PaymentHelper';
import Segment from '@/Segment';
import CreditCardDetailsForm from '@/pages/responsive-pages/authenticated/payment/CreditCardDetailsForm';
import PaymeInterface from '@/Payme';

export default {
  name: 'GiftcardCreditCard',
  components: {
    EmailInput: BaseUI.EmailInput,
    CreditCardDetailsForm,
    RiseupButton: BaseUI.RiseupButton,
    PersonalInfoForm,
    Callout: BaseUI.Callout,
  },
  mixins: [PaymentHelper],
  async created() {
    Segment.trackUserGot('GiftcardCreditCard_Entered');
  },
  beforeDestroy() {
    PaymeInterface.teardown();
  },
  data() {
    return {
      buttonClicked: false,
      invalidEmail: false,
      emailAddress: '',
    };
  },
  computed: {
    ...mapState('subscription', ['tokenizationError', 'loadingToken', 'type']),
    ...mapGetters('subscription', ['validPersonalInfo', 'subscriptionPrice']),
    validEmail() {
      return this.emailAddress.length > 2 && !this.invalidEmail;
    },
  },
  methods: {
    ...mapActions('subscription', ['purchaseGiftcard']),

    setEmailAddress(value) {
      this.emailAddress = value;
    },
    setIsEmailInvalid(value) {
      this.invalidEmail = value;
    },
    async onPaymentClicked() {
      this.buttonClicked = true;
      Segment.trackUserInteraction('GiftcardCreditCard_PaymentClicked');
      try {
        const res = await this.purchaseGiftcard({ email: this.emailAddress });
        this.$emit('done', { success: res, email: this.emailAddress });
      } finally {
        this.buttonClicked = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '@/scss/mixins';

.purchase {
  .header {
    width: 100%;
  }

  .callout {
    margin: 0 10px;
    background: #FFF7DF;
  }

  .separator {
    height: 1px;
    width: 100%;
    background: $riseup_gray_0;
  }

  .top-section {
    @include responsive-padding;
    margin-top: 30px;
    margin-bottom: 45px;

    .main-title {
      font-family: SimplerPro;
      font-weight: bold;
      line-height: 1.17;
      font-size: 22px;
      margin-bottom: 30px;
    }
  }

  .personal-info {
    @include responsive-padding;
    margin-top: 30px;
    margin-bottom: 30px;
    font-family: SimplerPro;

    .title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .email-explained {
      margin-top: -5px;
      padding-right: 5px;
      font-size: 14px;
    }
  }

  .credit-card-details {
    @include responsive-padding;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .summary {
    @include responsive-padding;
    margin-top: 20px;
    margin-bottom: 30px;

    .title {
      font-size: 18px;
      font-weight: bold;
      line-height: 26px;
    }

    .payment-details {
      display: flex;
      width: 100%;
      margin-bottom: 20px;

      .amount-title {
        padding-top: 10px;
        font-size: 14px;
        color: $riseup-blue;
      }

      .amount-wrapper {
        flex-grow: 1;
      }
    }

    .link {
      padding: 11px 0 34px 0;
      cursor: pointer;
      font-size: 18px;
      color: $riseup_blue;
      text-align: center;
      font-weight: bold;
    }

    .button-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;

      .riseup-button {
        width: 100%;
      }
    }

    .result-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 18px 16px;
      border-radius: 3px;
      background-color: rgba($riseup_gray_0, 0.4);

      .top {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: bold;
        font-size: 20px;
        color: $riseup_danger_red;
        margin-left: 20px;

        .icon {
          margin-left: 11px;
        }

        .message {
          font-size: 20px;
          font-weight: bold;
          color: $riseup_danger_red;
        }
      }

      .cta {
        margin-top: 5px;
        font-family: SimplerPro;
        font-size: 16px;
        line-height: 1.5;
      }
    }
  }
}
</style>
