<template>
  <sticky-cta-page :ctaProps=ctaProps :pageBackgroundColor="pageBackgroundColor" @click="buttonAction">
    <div class="giftcard flex-1 flex-column">

      <div class="ri-body margin-top-s margin-bottom-sm"> לתת את רייזאפ במתנה! </div>
      <div class="ri-large-headline margin-bottom-xs ri-alte"> לתת עתיד כלכלי טוב יותר למי שחשוב לך </div>
      <div class="ri-large-body">
        <div>
          הגיפטקארד שלנו מאפשר לך לתת במתנה שישה חודשים ברייזאפ ללא תשלום. לך זה יעלה 180 ₪ במקום 225 ₪, ולצד השני זה יהיה שווה הרבה יותר!
        </div>
        <div class="margin-top-sm">
          ההטבה ניתנת למימוש ללקוחות חדשים בלבד.
        </div>
      </div>

      <div class="flex-column flex-1 justify-content-center margin-top-s">
        <img :src="require('@/assets/riseup-giftcard/upsell-asset.png')" />
      </div>
    </div>
  </sticky-cta-page>
</template>

<script>
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'GiftcardWelcome',
  components: {
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
  },
  data() {
    return {
      pageBackgroundColor: BaseUI.Colors.riseupRed,
    };
  },
  created() {
    Segment.trackUserGot('RiseupGiftcard_Entered');
  },
  methods: {
    buttonAction() {
      Segment.trackUserInteraction('RiseupGiftcard_NextClicked');
      this.$emit('clicked');
    },
  },
  computed: {
    ctaProps() {
      return { buttonProps: { title: 'בואו נקנה את השובר!', color: BaseUI.Colors.riseupBeige }, backgroundColor: this.pageBackgroundColor };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/riseup-colors";
@import "~@riseupil/base-ui/src/scss/spacings";

.giftcard {
  background-color: $riseup_red;
  color: $riseup_white;
  padding: $ri-spacing-ml $ri-spacing-ml 0 $ri-spacing-ml;

  img {
    min-height: 350px
  }
}
</style>
