<template>
  <div class="purchase-done">
    <div class="top">
      <img class="header-background" :src="require('@/assets/riseup-giftcard/purchase_done.svg')">
    </div>
    <div class="message">
      <div class="text">הנה פרטי התשלום שלך:</div>
      <div class="name">סך הכל תשלום חד פעמי</div>
      <div class="value">180 ₪</div>
      <div class="name">כרטיס אשראי לחיוב</div>
      <div class="value">{{ formattedCC }}</div>
      <div class="name">אימייל אליו נשלח את הגיפטקארד</div>
      <div class="value">
        {{ this.email }}
      </div>
      <riseup-button title="חזרה לתזרים" :action="backToCashflow" :size="'small'"/>
    </div>
    <div class="footer">
      <div class="text">המשימה שלנו היא להוביל שינוי בחברה הישראלית יחד עם הלקוחות שלנו,
        ולעזור לכל אחד ואחת לצמוח כלכלית.</div>
      <div class="bold">רוצה לקחת חלק?</div>
      <div>זה קורה <a href="https://bit.ly/30X5Ng4" target="_blank" class="link">בקבוצת הפייסבוק שלנו,</a> מחכים לך!</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';

export default {
  name: 'GiftcardSuccess',
  components: {
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    email: { type: String, required: true },
  },
  created() {
    Segment.trackUserGot('GiftcardSuccess_Entered');
  },
  computed: {
    ...mapGetters('subscription', ['cardLast4Digits', 'subscriptionPrice']),
    formattedCC() {
      return `${this.cardLast4Digits} **** **** ****`;
    },
  },
  methods: {
    async backToCashflow() {
      Segment.trackUserGot('GiftcardSuccess_BackToCashflowClicked');
      await this.$router.push({ path: '/sr' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/riseup-colors";
@import '@/scss/mixins';

.purchase-done {
  position: relative;
  text-align: right;
  margin-bottom: 30px;

  .top {
    position: relative;
    text-align: center;
    margin-bottom: 30px;

    .header-background {
      width: 100%;
    }

    .title-container {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      align-items: center;
      color: $riseup_white;
      justify-content: center;

      .title {
        display: inline-block;
        @include title;
        line-height: 32px;
        white-space: pre-line;
      }
    }
  }

  .message {
    @include responsive-padding;

    .text {
      font-size: 20px;
      line-height: 1.2;
      margin-bottom: 18px;
    }

    .name {
      padding-top: 14px;
      font-size: 14px;
      line-height: 1;
      margin-bottom: 7px;
    }

    .value {
      font-size: 20px;
      font-weight: bold;
      line-height: 1;
      margin-bottom: 22px;
    }

    .riseup-button {
      width: 100%;
      margin-top: 10px;
    }
  }

  .footer {
    margin-top: 50px;
    padding: 0px 40px 30px 40px;
    font-size: 16px;
    line-height: 1.38;

    .link {
      font-size: 16px;
      font-weight: bold;
      text-decoration: underline;
      color: $riseup_blue;
    }

    .bold {
      font-weight: bold;
      margin-top: 22px;
    }
  }
}
</style>
